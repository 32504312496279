.shelf-container {
    text-align: center;
    margin: 20px;
}

.inputs {
    margin-bottom: 20px;
}

.inputs label {
    display: block;
    margin-bottom: 10px;
}

.shelf {
    display: grid;
    border: 2px solid #333;
    background-color: #fafafa;
}

.shelf-section {
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    color: #666;
}
